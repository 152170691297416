.collapse-btn {
  background: #eee;
  border-radius: 4px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  cursor: pointer;

  .trigger {
    font-size: 16px;
    transition: color 0.3s;
  }
}

.laststand-logo {
  height: 65px;
  margin: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .not-collapsed-img {
    height: 50px;
    object-fit: contain;
  }
  .collapsed-img{
    object-fit: contain;
    height: 20px;
  }
}

.ant-layout-header {
  box-shadow: 0 4px 2px -2px #ccc;
  z-index: 1;
  overflow: hidden;
}

.site-layout {
  background: #f5f6fa !important;

  .site-layout-background {
    background: #f5f6fa;
    overflow: hidden;
  }
}

.bugify-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bell-icon {
    font-size: 20px;
  }
}

.content-main-section {
  position: relative;
  padding: 0;
  margin: 0;
}

.ant-popover-inner-content {
  padding: 5px 10px !important;
}

.user-inner-container {
  .profile-opt {
    width: 150px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    column-gap: 15px;
    font-weight: 500;
  }

  .password-opt {
    width: 150px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    column-gap: 15px;
    font-weight: 500;
  }

  .logout-opt {
    display: flex;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    cursor: pointer;
    column-gap: 15px;
    font-weight: 500;
  }
}

.user-popover{
  cursor: pointer;
}
.ant-layout-sider-children {
  position: relative;

  .copyright-text {
    color: #a6b0c2;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
}
